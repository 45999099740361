import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
//import { Link } from "gatsby"
import { Helmet } from 'react-helmet'

import Link from '../components/Link'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/employee.scss'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import CustomButton from '../components/custom-button/custom-button.component.jsx'

import logo from '../images/logo.svg'
import employeePage from '../images/employee-pg.svg'

import employee1 from '../images/employee-s1.svg'
import employee2 from '../images/employee-s2.svg'
import employee3 from '../images/employee-s3.svg'
import employee4 from '../images/employee-s4.svg'
import employee5 from '../images/employee-s5.svg'
import employee6 from '../images/employee-s6.svg'
import employee7 from '../images/employee-s7.svg'
import employee8 from '../images/employee-s8.svg'

const Employee = ({ data }) => {
	const state = { productName: 'Employees Productivity', planTitle: 'Starter Plan', planPrice: '10 $' }
	const signupClick = () => {
		localStorage.setItem(
			'state',
			JSON.stringify({
				productName: state.productName,
				planTitle: state.planTitle,
				planPrice: state.planPrice,
			})
		)
	}
	return (
		<Fragment>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
			<Layout>
				{/* <SEO title="Employee Productivity Apps" /> */}
				<div className="employee-page">
					<div className="Header-parent">
						<MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer>
					</div>
					<div className="page-content employee-content">
						<div className="employee-section">
							<div className="header-content">
								<div className="header-items">
									<h1>Employee Productivity Apps</h1>
									<div className="hint">
										<h3>
											<span>Employee Productivity</span>
											<span>
												{' '}
												Platform is the best way to control
												your industry easy and fast, Now
												Drive to the future.
											</span>
										</h3>
									</div>
									<CustomButton>
										<Link
											className="demo-request"
											data-track-location="ilpapps"
											to="/pricing"
										>
											Request Demo
										</Link>
									</CustomButton>
								</div>
								<div className="header-imgs">
									<img src={employeePage} alt="services-left" />
								</div>
							</div>
						</div>
						<section className="products-content" id="products-content">
							<h1 className="product-header">Explore Features</h1>
							<div className="products-container">
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">
												Employees Multi Levels Objectives
												and Key Results Management
											</h3>
											<p className="subtitle">
												Create Objectives and Key Results
												with a multi level option , Control
												Objectives and Key Results
												Assignments , Track Objectives
												progress and more
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={employee1}
												className="item-img"
												alt="E-Commerce"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details">
											<h3 className="title">
												Time Management Features
											</h3>
											<p className="subtitle">
												Employees can record their time and
												progress easily with a single click
												, comprehensive time sheet that
												allows users to record multiple
												entries once.
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={employee2}
												className="item-img"
												alt="Catalog"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">Review Tasks</h3>
											<p className="subtitle">
												Managers have the ability to review
												each entry to make sure that
												everything on track.
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={employee3}
												className="item-img"
												alt="Service"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details">
											<h3 className="title">Dashboards</h3>
											<p className="subtitle">
												Employee Productivity provides an
												interactive Dashboards , Get all
												needed information from one view ,
												You can take a one look to see every
												thing you need to know about your
												employees.
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={employee4}
												className="item-img"
												alt="Marketing"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">Custody System</h3>
											<p className="subtitle">
												This is one of our applications in
												Employee Productivity family ,
												Custody System i ready as a solution
												to get your business controlled
												easily , Get a benefit of using
												Custody Solution as a part of
												Employee Productivity applications.
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={employee5}
												className="item-img"
												alt="Personalized"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details">
											<h3 className="title">
												Work Confirmation System
											</h3>
											<p className="subtitle">
												This is one of our applications in
												Employee Productivity family , you
												can get the Benefits of controlling
												your Projects and Deliverables to
												confirm work stages before issuing
												the collections to your providers.
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={employee6}
												className="item-img"
												alt="Objects"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">Feeds Time Line</h3>
											<p className="subtitle">
												You can see all the historical Feeds
												in a time line view with exporting
												features.
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={employee7}
												className="item-img"
												alt="Gallery"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details">
											<h3 className="title">
												Wiki and Docs Support
											</h3>
											<p className="subtitle">
												Upload your Documents and Wikis to
												help your employees in Employee
												Productivity Product.
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={employee8}
												className="item-img"
												alt="Promotions"
											/>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
					<div className="footer-section">
						<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
					</div>
				</div>
			</Layout>
		</Fragment>
	)
}
export const pageQuery = graphql`
	query EmployeeQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`
export default Employee
